<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
        <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                         :disabled="loading"/>
        <textarea-input name="description"
                        label="Description" v-model="model.description"/>
        <checkbox-input name="is_active" label="Status" v-model="model.is_active"/>

        <validated-vue-select multiple name="Channels" :options="channelOptions"
                              :rules="{required:true}" label="Channels" v-model="model.channels"/>
        <validated-ajax-vue-select multiple name="Customer Group" :url="groupOptions" :rules="{required:true}"
                                   label="Customer Group" v-model="model.customer_group"/>

        <validated-date-picker label="From" name="From" v-model="model.from_date"
                               :disabled="loading"/>
        <validated-date-picker label="To" name="To" v-model="model.to_date"
                               :disabled="loading"/>

        <h5 class="mt-4">Conditions</h5>
        <textarea-input name="Description" label="Description" v-model="model.condition_description"/>
        <validated-file-input name="Content" label="Content" v-model="model.condition_content"/>

        <h5 class="mt-4">Actions</h5>
        <validated-vue-select name="Type" label="Type" v-model="model.type" :options="typeOptions"/>
        <validated-input name="Amount" label="Amount" v-model="model.amount"/>
        <checkbox-input name="end_other_shipping" label="End Other Rules" v-model="model.end_other_shipping"/>
        <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
    </b-form>
</template>

<script>
import urls from '@/data/urls';

export default {
    name  : 'CatalogueRuleAdd',
    props : { typeOptions : Array },
    data () {
        return {
            addUrl         : urls.salesAdmin.marketing.promotions.catalogueRule.add,
            groupOptions   : urls.salesAdmin.MasterData.Group.vueSelect,
            channelOptions : [
                { value : 'Mobile Application', label : 'Mobile Application' },
                { value : 'Website', label : 'Website' }
            ]
        };
    },
    methods : {
        formSuccess () {
            const that = this;
            // that.$notify('Catalogue rule added successfully', 'Success', { type : 'success' });
            that.$emit('success');
        }
    }
};
</script>
