<template>
    <div class="m-3">
        <div class="fl-te-c mb-3">
            <h4>Promotions</h4>
            <div class="btn-group ml-3">

            </div>
        </div>
        <div class="card bs-4 p-0 of-h">
            <tabs class="">
                <tab title="Cart Rules" class="p-0">
                    <cart-rules ref="cart" :start_date="start_date.toString()" :end_date="end_date.toString()"
                                       :status="status.toString()" :searches="search"/>
                </tab>
                <tab title="Catalogue Rules" class="p-0">
                    <CatalogueRules :start_date="start_date.toString()" :end_date="end_date.toString()"
                                       :status="status.toString()" :searches="search"/>
                </tab>
            </tabs>
        </div>

        <modal no-close-on-backdrop title="Add Catalogue" ref="catalogueAddModal" width="45r">
            <CatalogueRuleAdd @success='formSuccess'/>
        </modal>

        <modal no-close-on-backdrop title="Add Cart" ref="cartAddModal" width="45r">
            <CartRuleAdd @success="formSuccess"/>
        </modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import CartRules from '../Marketing/CartRules/ListingComponent';
import CatalogueRules from '../Marketing/CatalogueRules/ListingComponent';
import CatalogueRuleAdd from '../Marketing/CatalogueRules/AddComponent';
import CartRuleAdd from '../Marketing/CartRules/AddComponent';

export default {
    name       : 'ViewPage',
    components : { CartRules, CatalogueRules, CatalogueRuleAdd, CartRuleAdd },
    data () {
        return {
            loading       : false,
            start_date    : '',
            search        : '',
            end_date      : '',
            status        : '',
            statusOptions : urls.purchaseAdmin.vendorOptions
        };
    },
    methods : {
        showCatalogueAddModal () {
            this.$refs.catalogueAddModal.show();
        },
        showCartAddModal () {
            this.$refs.cartAddModal.show();
        },
        formSuccess () {
            const that = this;
            that.cartAddModal.close();
            that.catalogueAddModal.close();
        }
    }
};
</script>
