<template>
    <div>
        <custom-vue-table title="" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                         :page-number="false" :extra-params="{ start_date, vendor, end_date, searches, status }">
            <template #filters>
                <div class="btn-group ml-3">
                    <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                        <div class="p-3">
                            <validated-vue-select name="Status" :options="statusOptions"
                                                  label="Status"
                                                  v-model="status"/>
                        </div>
                    </drop-btn>
                    <btn color="primary" size="md" @click="$refs.addModal.show()" icon="fa fa-plus" text="Add"/>
                </div>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn color="primary" class="btn-basic-b" size="xs" @click="setEdit(rowData)" text="Edit"/>
                    <delete-btn design="basic" size="xs" @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table>

        <modal ref="addModal" width="45r" title="Add Cart Rule">
            <add-component @success="formSuccess" :typeOptions="typeOptions"/>
        </modal>

        <modal ref="editModal" width="45r" title="Edit Cart Rule">
            <b-form :save-url="editUrl" @success="editComplete" v-slot="{model, loading}" :initial-data="editingItem">
                <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                 :disabled="loading"/>
                <textarea-input name="Default Address"
                                label="Description" v-model="model.description"/>
                <checkbox-input name="is_active" label="Status" v-model="model.is_active"/>

                <validated-vue-select name="Channels" :options="channelOptions"
                                      :rules="{required:true}" label="Channels" v-model="model.channel"/>
                <validated-ajax-vue-select multiple name="Customer Group" :url="groupOptions" :rules="{required:true}"
                                           label="Customer Group" v-model="model.customer_group"/>
                <validated-input label="Coupon type" name="Coupon type" v-model="model.coupon_type" :rules="{required:true}"/>

                <validated-date-picker label="From" name="From" v-model="model.from_date"
                                       :disabled="loading"/>
                <validated-date-picker label="To" name="To" v-model="model.to_date"
                                       :disabled="loading"/>

                <h5 class="mt-4">Conditions</h5>
                <textarea-input name="Description" label="Description" v-model="model.condition_description"/>
                <validated-file-input name="Content" label="Content" v-model="model.condition_content"/>

                <h5 class="mt-4">Actions</h5>
                <validated-vue-select name="Type" label="Type" v-model="model.type" :options="typeOptions"/>
                <validated-input name="Amount" label="Amount" v-model="model.amount"/>
                <validated-input name="max_quantity_discount" label="Maximum Quantity Allowed to be Discounted" v-model="model.max_quantity_discount"/>
                <validated-input name="buy_x_quantity" label="Buy X Quantity" v-model="model.buy_x_quantity"/>
                <checkbox-input name="apply_to_shipping" label="Apply to Shipping" v-model="model.apply_to_shipping"/>
                <checkbox-input name="free_shipping" label="Free Shipping" v-model="model.free_shipping"/>
                <checkbox-input name="end_other_shipping" label="End Other Rules" v-model="model.end_other_shipping"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Cart rule. Are you sure ?
            </p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/urls';
import AddComponent from '@/views/SalesAdmin/Marketing/CartRules/AddComponent';

export default {
    name       : 'ListingComponent',
    components : { AddComponent },
    props      : {
        start_date : { type : String },
        end_date   : { type : String },
        vendor     : { type : String },
        status     : { type : String },
        searches   : { type : String }
    },
    data () {
        return {
            loading       : false,
            deletingItem  : null,
            editUrl       : urls.salesAdmin.marketing.promotions.cartRule.edit,
            listUrl       : urls.salesAdmin.marketing.promotions.cartRule.list,
            deleteUrl     : urls.salesAdmin.marketing.promotions.cartRule.delete,
            editingItem   : null,
            statusOptions : [
                { value : 'True', label : 'Active' },
                { value : 'False', label : 'Inactive' }
            ],
            groupOptions   : urls.salesAdmin.MasterData.Group.vueSelect,
            channelOptions : [
                { value : 'Mobile Application', label : 'Mobile Application' },
                { value : 'Website', label : 'Website' }
            ],
            typeOptions : [
                { label : 'Fixed Amount', value : 'Fixed Amount' },
                { label : 'Buy X Get Y Free', value : 'Buy X Get Y Free' },
                { label : 'Fixed Amount to Whole Cart', value : 'Fixed Amount to Whole Cart' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'from_date',
                    sortField : 'from_date',
                    title     : 'Start Date'
                },
                {
                    name      : 'to_date',
                    sortField : 'to_date',
                    title     : 'End Date'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/sales/email-marketing/' + rowData.id + '/cart-rule-details/');
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        editComplete () {
            this.editingItem = null;
            this.$refs.editModal.close();
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            const that = this;
            that.deletingItem = null;
            that.$refs.deleteModal.close();
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
        }
    }
};
</script>
