<template>
    <b-form :save-url="addUrl" @success="cartFormSuccess" v-slot="{model, loading}">
        <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                         :disabled="loading"/>
        <textarea-input name="Default Address"
                        label="Description" v-model="model.description"/>
        <checkbox-input name="is_active" label="Status" v-model="model.is_active"/>

        <validated-vue-select name="Channels" :options="channelOptions"
                              :rules="{required:true}" label="Channels" v-model="model.channel"/>
        <validated-ajax-vue-select multiple name="Customer Group" :url="groupOptions" :rules="{required:true}"
                                   label="Customer Group" v-model="model.customer_group"/>
        <validated-input label="Coupon type" name="Coupon type" v-model="model.coupon_type" :rules="{required:true}"/>

        <validated-date-picker label="From" name="from_date" v-model="model.from_date"
                               :disabled="loading"/>
        <validated-date-picker label="To" name="to_date" v-model="model.to_date"
                               :disabled="loading"/>

        <h5 class="mt-4">Conditions</h5>
        <textarea-input name="Description" label="Description" v-model="model.condition_description"/>
        <validated-file-input name="Content" label="Content" v-model="model.condition_content"/>

        <h5 class="mt-4">Actions</h5>
        <validated-vue-select name="Type" label="Type" v-model="model.type" :options="typeOptions"/>
        <validated-input name="Amount" label="Amount" v-model="model.amount"/>
        <validated-input type="number" name="max_quantity_discount" label="Maximum Quantity Allowed to be Discounted" v-model="model.max_quantity_discount"/>
        <validated-input name="buy_x_quantity" label="Buy X Quantity" v-model="model.buy_x_quantity"/>
        <checkbox-input name="apply_to_shipping" label="Apply to Shipping" v-model="model.apply_to_shipping"/>
        <checkbox-input name="free_shipping" label="Free Shipping" v-model="model.free_shipping"/>
        <checkbox-input name="end_other_shipping" label="End Other Rules" v-model="model.end_other_shipping"/>
        <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
    </b-form>
</template>

<script>
import urls from '@/data/urls';

export default {
    name  : 'CartRuleAdd',
    props : { typeOptions : Array },
    data () {
        return {
            loading        : false,
            addUrl         : urls.salesAdmin.marketing.promotions.cartRule.add,
            groupOptions   : urls.salesAdmin.MasterData.Group.vueSelect,
            channelOptions : [
                { value : 'Mobile Application', label : 'Mobile Application' },
                { value : 'Website', label : 'Website' }
            ]
        };
    },
    methods : {
        cartFormSuccess () {
            const that = this;
            // that.$notify('Cart Rule added successfully', 'Success', { type : 'success' });
            that.$emit('success');
        }
    }
};
</script>
